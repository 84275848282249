// Style page "Artiste"

  .artiste-id {
    table {
      margin: 0;
      table-layout: auto;

      th {
        text-align: right;
      }

      th, td {
        padding: .5rem;
        vertical-align: baseline;
      }
    }
  }

  .artiste-bio {
    img {
      display: block;
      max-width: 100%;
      width: 50rem;
      height: auto;
      margin: 0 auto 3rem;
    }
  }
