
	// Styles de formulaire

		// Reset

			input[type=number] {
				-moz-appearance: textfield;
			}

			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input:-webkit-autofill { // Background Yellow on Chrome
			 -webkit-box-shadow: 0 0 0 3rem white inset;
			}

			::-ms-clear {
	     display: none;
	  	}

	  	::-ms-reveal {
	     display: none;
	  	}

      label, input, textarea {
        width: 100%;
      }

      label {
        margin-bottom: 1rem;
        font-weight: 400;
      }

	  	input[type=text], textarea, #form_message {
	    	-webkit-appearance: none;
	   	 	-moz-appearance: none;
	    	appearance: none;
        outline: none;
        padding: .7rem 1rem;
        font-weight: 400;
        border-radius: 0;
        box-shadow: 0 0 0 .2rem $ocre inset;
        background-color: transparent;
        transition: all .3s ease-in-out;

        &:hover {
          background-color: rgba($white, .5);
        }

        &:focus {
          box-shadow: 0 0 0 .2rem $anthracite inset;
          background-color: $white;
        }
	    }

      .form-group {
        margin: 1rem 0 0;
      }

      .form-group-wrapper {
        display: flex;
        margin: -1rem;

        @media (max-width: $small) {
          flex-wrap: wrap;
          margin: 0;
        }

        .form-group {
          width: 50%;
          margin: 1rem 1rem 2rem;

          @media (max-width: $small) {
            width: 100%;
            margin: 1rem 0 0;
          }
        }
      }

		// Placeholder

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				font-size: 1.4rem;
        color: rgba($anthracite, .8);
			}

			::-moz-placeholder { /* Firefox 19+ */
				font-size: 1.4rem;
        color: rgba($anthracite, .8);
			}

			:-ms-input-placeholder { /* IE 10+ */
				font-size: 1.4rem;
        color: rgba($anthracite, .8);
			}

			:-moz-placeholder { /* Firefox 18- */
				font-size: 1.4rem;
        color: rgba($anthracite, .8);
			}

