// Style oeuvres single et page

  // Style oeuvre vignette

  .oeuvre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 25%;
    padding: 2rem;
    transition: background-color 1s $ease-slow;

    @media (max-width: $large) {
      width: 33.33%;
    }

    @media (max-width: $small) {
      width: 50%;
    }

    @media (max-width: $tiny) {
      padding: 1rem;
    }

    &:hover {
      background-color: $ivoire-clair;
    }

    .oeuvre-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .oeuvre-titre {
      margin: 0;
      text-align: center;
      font: 400 1.4rem $font-family-base;
      text-transform: uppercase;
    }

    .oeuvre-pic {
      display: block;
      margin: 0 auto 1.5rem;
    }
  }

  // Style oeuvre catégorie

  .oeuvres-content {
    padding-bottom: 7rem;

    @media (max-width: $medium) {
      padding-bottom: 4rem;
    }

    @media (max-width: $tiny) {
      padding-bottom: 2rem;
    }

    .categorie-soustitre {
      margin: .5rem 0 0;
      font: 500 1.6rem $font-family-headings;
      font-style: italic;
    }
  }

  // Style oeuvre single

  .oeuvre-content {
    padding-bottom: 7rem;

    @media (max-width: $medium) {
      padding-bottom: 4rem;
    }

    @media (max-width: $tiny) {
      padding-bottom: 2rem;
    }

    & > .flex-container {
      @media (max-width: $medium) {
        flex-direction: column;
        align-items: center;
      }
    }

    .slider-wrapper, .content-wrapper {
      width: 50%;

      @media (max-width: $medium) {
        width: 100%;
      }
    }

    .slider-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .galerie-oeuvre {
      width: 55rem;
      outline: none;

      a {
        outline: none;
      }

      div {
        text-align: center;
      }
    }

    .oeuvre-pic-big {
      max-height: 55rem;
      max-width: 55rem;
    }

    .oeuvre-single-pic {
      width: initial;
      height: auto;
      margin: 0 auto;
    }

    .slick-dots {
      button:before {
        border: .2rem solid $ocre;
      }

      .slick-active button:before {
        background-color: $ocre;
      }
    }

    .content-wrapper {
      padding: 0 2rem;

      @media (max-width: $medium) {
        padding: 3rem 0 0;
        text-align: center;
      }

      @media (max-width: $tiny) {
        padding-top: 2rem;
      }
    }

    .vente {
      margin-top: 3rem;

      @media (max-width: $tiny) {
        margin-top: 2rem;
      }

      strong {
        font-size: 1.8rem;
        color: $success;

        @media (max-width: $tiny) {
          font-size: 1.6rem;
        }
      }
    }

    .vente-btn-wrapper {
      align-items: center;

      @media (max-width: $medium) {
        justify-content: center;
      }
    }

    .vente-btn {
      margin-bottom: 1rem;
      padding: .6rem .7rem .4rem;
      cursor: pointer;
      font: 500 1.7rem $font-family-headings;
      text-decoration: none;
      text-align: center;
      color: $white;
      background-color: $anthracite;
      transition: background-color 1s $ease-slow;

      &:first-of-type {
        margin-right: 1rem;
      }

      &:hover {
        background-color: $ocre;
      }
    }

    .basic-btn {
      display: inline-block;

      svg {
        margin-right: 1rem;
        fill: $white;
      }

      span, svg {
        vertical-align: middle;
      }
    }
  }
