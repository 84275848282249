// Style actualités single et page

  .news-single {
    position: relative;

    &:hover .fake-btn {
      background-color: $ocre;
    }

    .news-single-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .news-single-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;

      & > div {
        max-width: 100%;
      }
    }

    .entete-wrapper {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin-bottom: 2rem;
      padding-bottom: 2rem;

      @media (max-width: $tiny) {
        flex-direction: column;
      }

      &:after {
        position: absolute;
        left: 0;
        bottom: -.1rem;
        width: 5rem;
        height: .2rem;
        content: '';
        background-color: $ocre;
      }
    }

    .entete-image {
      min-width: 11.5rem;
      margin-right: 2rem;

      @media (max-width: $tiny) {
        order: 1;
        margin: 1.5rem 0 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    h2, h3 {
      margin-bottom: 1rem;
      font: 400 2.1rem $font-family-base;

      @media (max-width: $tiny) {
        font-size: 1.8rem;
      }
    }

    .news-single-date {
      margin: 0;
      font: 500 1.5rem $font-family-headings;
      font-style: italic;
    }

    .txt-wrapper {
      margin-bottom: 2rem;
    }

    .fake-btn {
      margin-top: auto;
      padding: 0 .3rem;
      font-size: 1.3rem;
      text-transform: uppercase;
      color: $white;
      background-color: $anthracite;
      transition: background-color 1s $ease-slow;
    }
  }

  // Style page Actualités

  .actualites-content {
    padding-bottom: 7rem;

    @media (max-width: $medium) {
      padding-bottom: 4rem;
    }

    @media (max-width: $tiny) {
      padding-bottom: 2rem;
    }

    .news-single {
      width: 50%;
      padding: 3rem 2rem;
      transition: background-color 1s $ease-slow;

      @media (max-width: $medium-plus) {
        width: 100%;
        padding: 2rem;
      }

      @media (max-width: $small) {
        padding: 1.5rem 1rem;
      }

      &:hover {
        background-color: $ivoire-clair;
      }
    }
  }

  // Style page Actualite single

  .actualite-content {
    img {
      width: 100%;
      height: auto;
    }

    hr {
      margin: 0;
      color: rgba($ocre, .5);
      background-color: rgba($ocre, .5);
    }

    .news-duree {
      margin-bottom: 2rem;
      font: 500 2.2rem $font-family-headings;
      text-align: center;
    }

    a:not(.basic-btn) {
      color: $ocre;
    }
  }
