
	// Fonts (Polices de caractère)

		//// Slick

      @font-face {
        font-family: 'slick';
          src: url('../fonts/slick/slick.woff2') format('woff2'),
             url('../fonts/slick/slick.woff') format('woff'),
               url('../fonts/slick/slick.ttf') format('truetype'),
           url('../fonts/slick/slick.svg#Slick') format('svg');
      }

    // Garamond

      @font-face {
          font-family: 'Garamond';
          src: url('../fonts/garamond/garamond-bold.woff2') format('woff2'),
               url('../fonts/garamond/garamond-bold.woff') format('woff'),
               url('../fonts/garamond/garamond-bold.ttf') format('truetype'),
           url('../fonts/garamond/garamond-bold.svg#GaramondBold') format('svg');
          font-weight: 700;
          font-style: normal;
      }

      @font-face {
          font-family: 'Garamond';
          src: url('../fonts/garamond/garamond-regular.woff2') format('woff2'),
               url('../fonts/garamond/garamond-regular.woff') format('woff'),
               url('../fonts/garamond/garamond-regular.ttf') format('truetype'),
           url('../fonts/garamond/garamond-regular.svg#GaramondRegular') format('svg');
          font-weight: 500;
          font-style: normal;
      }

      @font-face {
          font-family: 'Garamond';
          src: url('../fonts/garamond/garamond-italic.woff2') format('woff2'),
               url('../fonts/garamond/garamond-italic.woff') format('woff'),
               url('../fonts/garamond/garamond-italic.ttf') format('truetype'),
           url('../fonts/garamond/garamond-italic.svg#GaramondItalic') format('svg');
          font-weight: 500;
          font-style: italic;
      }

    // Roboto

      @font-face {
          font-family: 'Roboto';
          src: url('../fonts/roboto/roboto-light.woff2') format('woff2'),
               url('../fonts/roboto/roboto-light.woff') format('woff'),
               url('../fonts/roboto/roboto-light.ttf') format('truetype'),
           url('../fonts/roboto/roboto-light.svg#RobotoLight') format('svg');
          font-weight: 300;
          font-style: normal;
      }

      @font-face {
          font-family: 'Roboto';
          src: url('../fonts/roboto/roboto-lightitalic.woff2') format('woff2'),
               url('../fonts/roboto/roboto-lightitalic.woff') format('woff'),
               url('../fonts/roboto/roboto-lightitalic.ttf') format('truetype'),
           url('../fonts/roboto/roboto-lightitalic.svg#RobotoLightItalic') format('svg');
          font-weight: 300;
          font-style: italic;
      }

      @font-face {
          font-family: 'Roboto';
          src: url('../fonts/roboto/roboto-regular.woff2') format('woff2'),
               url('../fonts/roboto/roboto-regular.woff') format('woff'),
               url('../fonts/roboto/roboto-regular.ttf') format('truetype'),
           url('../fonts/roboto/roboto-regular.svg#RobotoRegular') format('svg');
          font-weight: 400;
          font-style: normal;
      }

      @font-face {
          font-family: 'Roboto';
          src: url('../fonts/roboto/roboto-italic.woff2') format('woff2'),
               url('../fonts/roboto/roboto-italic.woff') format('woff'),
               url('../fonts/roboto/roboto-italic.ttf') format('truetype'),
           url('../fonts/roboto/roboto-italic.svg#RobotoItalic') format('svg');
          font-weight: 400;
          font-style: italic;
      }

