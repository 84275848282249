
	// Pied de page

	footer {
    position: relative;
		width: 100%;
    padding: 2rem !important;
    font: 500 1.3rem $font-family-headings;
    overflow: hidden;
    background-color: $ivoire-clair;

    p {
      margin: 0;
    }

    .footer__content, .footer__bottom {
      position: relative;
      z-index: 1;
    }

    .footer__content {
      padding: 2rem 0 4rem;

      @media (max-width: $tiny) {
        padding: 1rem 0 2rem;

        & > .flex-container {
          flex-direction: column;
          align-items: center;
        }
      }

      .logo-richard-apprederis {
        width: 12rem;
        height: 6rem;
        margin-bottom: 1rem;
        fill: $ocre;
      }

      .content-item {
        @media (max-width: $tiny) {
          text-align: center;
        }

        &.adresse, &.oeuvres {
          @media (min-width: $small + 1) {
            margin: 0 0 0 3rem;
            text-align: right;
          }

          @media (max-width: $tiny) {
            text-align: center;
          }
        }

        &.oeuvres {
          @media (min-width: $tiny + 1) {
            max-width: 16rem;
          }

          @media (max-width: $small) {
            margin-right: 3rem;
          }

          @media (max-width: $tiny) {
            margin: 0 0 2rem;
          }

          .title {
            margin-bottom: 1rem;
          }
        }

        &.adresse .title {
          margin-bottom: 2rem;
        }

        &.resume {
          @media (min-width: $small + 1) {
            max-width: 20rem;
            margin-right: auto;
          }

          @media (max-width: $small) {
            margin-bottom: 2rem;
          }
        }

        .title {
          font: 700 1.5rem $font-family-headings;
        }

        .contact-btn, .footer-oeuvres-link {
          display: inline-block;
          margin: 1rem .25rem 0;
          padding: .1rem .2rem;
          text-align: center;
          text-decoration: none;
          color: $white;
          transition: background-color 1s $ease-slow;
        }

        .contact-btn {
          font: 500 1.5rem $font-family-headings;
          background-color: $anthracite;

          &:hover {
            background-color: $ocre;
          }
        }

        .footer-oeuvres-link {
          font: 500 1.4rem $font-family-headings;
          background-color: $ocre;

          &:hover {
            background-color: $anthracite;
          }
        }
      }
    }

    .footer__bottom {
      padding-top: 2rem;
      border-top: .1rem solid $ocre;
      text-align: center;
    }

    .footer__btn-top {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      bottom: 2rem;
      width: 4rem;
      height: 4.4rem;
      cursor: pointer;
      outline: none;
      font: 400 2rem $font-family-base;
      border: .2rem solid $ocre;
      border-right: none;
      color: $ocre;
      background-color: transparent;
      transition: all .7s $ease-slow;
      z-index: 4;

      span {
        margin-top: -.5rem;
        margin-left: -.2rem;
      }

      &:hover {
        color: $white;
        background-color: $ocre;
      }
    }

    .bg-deco {
      position: absolute;
      top: 0;
      left: -5%;
      width: 100%;
      z-index: 0;

      .logo-richard-apprederis {
        width: 80%;
        height: 80%;
        fill: $ivoire;
      }
    }
	}
