// Style page d'accueil

  .accueil-categories {
    .categories-wrapper {
      margin: -1rem;
    }

    .vign-categorie {
      position: relative;
      width: 25%;
      padding: 1rem;

      @media (max-width: $large) {
        width: 33.33%;
      }

      @media (max-width: $small) {
        width: 50%;
      }

      &:hover:before {
        background-color: rgba($ocre, .3);
      }

      &:before {
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        content: '';
        transition: background-color 1s $ease-slow;
        z-index: 1;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
      }

      h2 {
        position: absolute;
        top: 50%;
        left: 1rem;
        right: 1rem;
        margin: 0;
        padding: 1rem;
        font-size: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        transform: translateY(-50%);
        color: $white;
        z-index: 2;

        @media (max-width: $large) {
          font-size: 2rem;
        }

        @media (max-width: $tiny) {
          font-size: 1.4rem;
        }

        span {
          padding: .7rem .7rem .2rem;
          background-color: $anthracite;

          @media (max-width: $tiny) {
            padding: .5rem .7rem .2rem;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
