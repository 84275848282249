// Style page Contact

  .contact-form {
    .g-recaptcha {
      margin-top: 3rem;

      @media (max-width: $small) {
        margin-top: 2rem;
      }

      & > div {
        margin: 0 auto;
      }
    }

    #rc-imageselect, .g-recaptcha {
      @media (max-width: $tiny) {
        transform: scale(0.8) !important;
        transform-origin: 0 0 !important;
      }
    }
  }
