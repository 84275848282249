@charset "UTF-8";
/*!
* www.KNACSS.com v7.0.3 (march, 1 2018) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/
/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */
/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*# sourceMappingURL=bootstrap-reboot.css.map */
/* ----------------------------- */
/* ==Base (basic styles)         */
/* ----------------------------- */
/* switching to border-box model for all elements */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit;
  /* avoid min-width: auto on flex and grid children */
  min-width: 0;
  min-height: 0; }

html {
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625); }

body {
  margin: 0;
  font-size: 1.4rem;
  background-color: #fff;
  color: #212529;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.5; }
  @media (min-width: 576px) {
    body {
      font-size: 1.6rem; } }

/* Links */
a {
  color: #333;
  text-decoration: underline; }
  a:focus, a:hover, a:active {
    color: #0d0d0d;
    text-decoration: underline; }

/* Headings */
h1, .h1-like {
  font-size: 2.8rem;
  font-family: "Garamond", Georgia, serif;
  font-weight: 500; }
  @media (min-width: 576px) {
    h1, .h1-like {
      font-size: 3.2rem; } }

h2, .h2-like {
  font-size: 2.4rem;
  font-family: "Garamond", Georgia, serif;
  font-weight: 500; }
  @media (min-width: 576px) {
    h2, .h2-like {
      font-size: 2.8rem; } }

h3, .h3-like {
  font-size: 2rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h3, .h3-like {
      font-size: 2.4rem; } }

h4, .h4-like {
  font-size: 1.8rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h4, .h4-like {
      font-size: 2rem; } }

h5, .h5-like {
  font-size: 1.6rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h5, .h5-like {
      font-size: 1.8rem; } }

h6, .h6-like {
  font-size: 1.4rem;
  font-weight: 500; }
  @media (min-width: 576px) {
    h6, .h6-like {
      font-size: 1.6rem; } }

/* Vertical rythm */
h1,
h2,
h3,
h4,
h5,
h6,
dd {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p,
address,
ol,
ul,
dl,
blockquote,
pre {
  margin-top: 0;
  margin-bottom: 1rem; }

/* Avoid margins on nested elements */
li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0; }

/* Max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
  max-width: 100%; }

img {
  height: auto; }

/* Styling elements */
ul,
ol {
  padding-left: 2em; }

img {
  vertical-align: middle; }

em,
.italic,
address,
cite,
i,
var {
  font-style: italic; }

code,
kbd,
mark {
  border-radius: 2px; }

kbd {
  padding: 0 2px;
  border: 1px solid #999; }

pre {
  tab-size: 2; }

code {
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.04);
  color: #b11; }

pre code {
  padding: 0;
  background: none;
  color: inherit;
  border-radius: 0; }

mark {
  padding: 2px 4px; }

sup,
sub {
  vertical-align: 0; }

sup {
  bottom: 1ex; }

sub {
  top: 0.5ex; }

blockquote {
  position: relative;
  padding-left: 3em;
  min-height: 2em; }

blockquote::before {
  content: "\201C";
  position: absolute;
  left: 0;
  top: 0;
  font-family: georgia, serif;
  font-size: 5em;
  height: .4em;
  line-height: .9;
  color: #e7e9ed; }

blockquote > footer {
  margin-top: .75em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7); }
  blockquote > footer::before {
    content: "\2014 \0020"; }

q {
  font-style: normal; }

q,
.q {
  quotes: "“" "”" "‘" "’"; }
  q:lang(fr),
  .q:lang(fr) {
    quotes: "«\00a0" "\00a0»" "“" "”"; }

hr {
  display: block;
  clear: both;
  height: 1px;
  margin: 1em 0 2em;
  padding: 0;
  border: 0;
  color: #ccc;
  background-color: #ccc; }

blockquote,
figure {
  margin-left: 0;
  margin-right: 0; }

code,
pre,
samp,
kbd {
  white-space: pre-wrap;
  font-family: consolas, courier, monospace;
  line-height: normal; }

/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt; }
  p,
  .p-like,
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  h4,
  .h4-like,
  h5,
  .h5-like,
  h6,
  .h6-like,
  blockquote,
  label,
  ul,
  ol {
    color: #000;
    margin: auto; }
  .print {
    display: block; }
  .no-print {
    display: none; }
  /* no orphans, no widows */
  p,
  .p-like,
  blockquote {
    orphans: 3;
    widows: 3; }
  /* no breaks inside these elements */
  blockquote,
  ul,
  ol {
    page-break-inside: avoid; }
  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */
  /* no breaks after these elements */
  h1,
  .h1-like,
  h2,
  .h2-like,
  h3,
  .h3-like,
  caption {
    page-break-after: avoid; }
  a {
    color: #000; }
  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: ""; } }

/* ----------------------------- */
/* ==Global Micro Layout         */
/* ----------------------------- */
/* module, gains superpower "BFC" Block Formating Context */
.mod,
.bfc {
  overflow: hidden; }

/* blocks that needs to be placed under floats */
.clear {
  clear: both; }

/* blocks that must contain floats */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
  border-collapse: collapse; }

/* simple blocks alignment */
.left {
  margin-right: auto; }

.right {
  margin-left: auto; }

.center {
  margin-left: auto;
  margin-right: auto; }

/* text and contents alignment */
.txtleft {
  text-align: left; }

.txtright {
  text-align: right; }

.txtcenter {
  text-align: center; }

/* floating elements */
.fl {
  float: left; }

img.fl {
  margin-right: 1rem; }

.fr {
  float: right; }

img.fr {
  margin-left: 1rem; }

img.fl,
img.fr {
  margin-bottom: 0.5rem; }

/* inline-block */
.inbl {
  display: inline-block;
  vertical-align: top; }

/* flexbox layout
http://www.alsacreations.com/tuto/lire/1493-css3-flexbox-layout-module.html
*/
.flex-container, .flex-container--row,
.flex-row, .flex-container--column,
.flex-column, .flex-container--row-reverse,
.flex-row-reverse, .flex-container--column-reverse,
.flex-column-reverse,
.d-flex {
  display: flex;
  flex-wrap: wrap; }

.flex-container--row,
.flex-row {
  flex-direction: row; }

.flex-container--column,
.flex-column {
  flex-direction: column; }

.flex-container--row-reverse,
.flex-row-reverse {
  flex-direction: row-reverse;
  justify-content: flex-end; }

.flex-container--column-reverse,
.flex-column-reverse {
  flex-direction: column-reverse;
  justify-content: flex-end; }

.flex-item-fluid,
.item-fluid {
  flex: 1 1 0%; }

.flex-item-first,
.item-first {
  order: -1; }

.flex-item-medium,
.item-medium {
  order: 0; }

.flex-item-last,
.item-last {
  order: 1; }

.flex-item-center,
.item-center,
.mr-auto {
  margin: auto; }

/* ---------------------------------- */
/* ==Helpers                          */
/* ---------------------------------- */
/* Typo Helpers  */
/* ------------- */
.u-bold {
  font-weight: 700; }

.u-italic {
  font-style: italic; }

.u-normal {
  font-weight: normal;
  font-style: normal; }

.u-uppercase {
  text-transform: uppercase; }

.u-lowercase {
  text-transform: lowercase; }

.u-smaller {
  font-size: 0.6em; }

.u-small {
  font-size: 0.8em; }

.u-big {
  font-size: 1.2em; }

.u-bigger {
  font-size: 1.5em; }

.u-biggest {
  font-size: 2em; }

.u-txt-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto; }

.u-txt-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis; }

/* State Helpers */
/* ------------- */
/* invisible for all */
.is-hidden,
.js-hidden,
[hidden] {
  display: none; }

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden, .tabs-content-item[aria-hidden="true"] {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important; }

.is-disabled,
.js-disabled,
[disabled],
.is-disabled ~ label,
[disabled] ~ label {
  opacity: 0.5;
  cursor: not-allowed !important;
  filter: grayscale(1); }

ul.is-unstyled, ul.unstyled {
  list-style: none;
  padding-left: 0; }

.color--inverse {
  color: #fff; }

/* Width Helpers */
/* ------------- */
/* blocks widths (percentage and pixels) */
.w100 {
  width: 100%; }

.w95 {
  width: 95%; }

.w90 {
  width: 90%; }

.w85 {
  width: 85%; }

.w80 {
  width: 80%; }

.w75 {
  width: 75%; }

.w70 {
  width: 70%; }

.w65 {
  width: 65%; }

.w60 {
  width: 60%; }

.w55 {
  width: 55%; }

.w50 {
  width: 50%; }

.w45 {
  width: 45%; }

.w40 {
  width: 40%; }

.w35 {
  width: 35%; }

.w30 {
  width: 30%; }

.w25 {
  width: 25%; }

.w20 {
  width: 20%; }

.w15 {
  width: 15%; }

.w10 {
  width: 10%; }

.w5 {
  width: 5%; }

.w66 {
  width: calc(100% / 3 * 2); }

.w33 {
  width: calc(100% / 3); }

.wauto {
  width: auto; }

.w960p {
  width: 960px; }

.mw960p {
  max-width: 960px; }

.w1140p {
  width: 1140px; }

.mw1140p {
  max-width: 1140px; }

.w1000p {
  width: 1000px; }

.w950p {
  width: 950px; }

.w900p {
  width: 900px; }

.w850p {
  width: 850px; }

.w800p {
  width: 800px; }

.w750p {
  width: 750px; }

.w700p {
  width: 700px; }

.w650p {
  width: 650px; }

.w600p {
  width: 600px; }

.w550p {
  width: 550px; }

.w500p {
  width: 500px; }

.w450p {
  width: 450px; }

.w400p {
  width: 400px; }

.w350p {
  width: 350px; }

.w300p {
  width: 300px; }

.w250p {
  width: 250px; }

.w200p {
  width: 200px; }

.w150p {
  width: 150px; }

.w100p {
  width: 100px; }

.w50p {
  width: 50px; }

/* Spacing Helpers */
/* --------------- */
.man,
.ma0 {
  margin: 0; }

.pan,
.pa0 {
  padding: 0; }

.mas {
  margin: 1rem; }

.mam {
  margin: 2rem; }

.mal {
  margin: 4rem; }

.pas {
  padding: 1rem; }

.pam {
  padding: 2rem; }

.pal {
  padding: 4rem; }

.mtn,
.mt0 {
  margin-top: 0; }

.mts {
  margin-top: 1rem; }

.mtm {
  margin-top: 2rem; }

.mtl {
  margin-top: 4rem; }

.mrn,
.mr0 {
  margin-right: 0; }

.mrs {
  margin-right: 1rem; }

.mrm {
  margin-right: 2rem; }

.mrl {
  margin-right: 4rem; }

.mbn,
.mb0 {
  margin-bottom: 0; }

.mbs {
  margin-bottom: 1rem; }

.mbm {
  margin-bottom: 2rem; }

.mbl {
  margin-bottom: 4rem; }

.mln,
.ml0 {
  margin-left: 0; }

.mls {
  margin-left: 1rem; }

.mlm {
  margin-left: 2rem; }

.mll {
  margin-left: 4rem; }

.mauto {
  margin: auto; }

.mtauto {
  margin-top: auto; }

.mrauto {
  margin-right: auto; }

.mbauto {
  margin-bottom: auto; }

.mlauto {
  margin-left: auto; }

.ptn,
.pt0 {
  padding-top: 0; }

.pts {
  padding-top: 1rem; }

.ptm {
  padding-top: 2rem; }

.ptl {
  padding-top: 4rem; }

.prn,
.pr0 {
  padding-right: 0; }

.prs {
  padding-right: 1rem; }

.prm {
  padding-right: 2rem; }

.prl {
  padding-right: 4rem; }

.pbn,
.pb0 {
  padding-bottom: 0; }

.pbs {
  padding-bottom: 1rem; }

.pbm {
  padding-bottom: 2rem; }

.pbl {
  padding-bottom: 4rem; }

.pln,
.pl0 {
  padding-left: 0; }

.pls {
  padding-left: 1rem; }

.plm {
  padding-left: 2rem; }

.pll {
  padding-left: 4rem; }

/* -------------------------- */
/* ==Responsive helpers       */
/* -------------------------- */
/* large screens */
/* ------------- */
@media (min-width: 992px) {
  /* layouts for large screens */
  .large-hidden {
    display: none !important; }
  .large-visible {
    display: block !important; }
  .large-no-float {
    float: none; }
  .large-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for large screens */
  .large-w25 {
    width: 25% !important; }
  .large-w33 {
    width: 33.333333% !important; }
  .large-w50 {
    width: 50% !important; }
  .large-w66 {
    width: 66.666666% !important; }
  .large-w75 {
    width: 75% !important; }
  .large-w100,
  .large-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for large screens */
  .large-man,
  .large-ma0 {
    margin: 0 !important; } }

/* medium screens */
/* -------------- */
@media (min-width: 768px) and (max-width: 991px) {
  /* layouts for medium screens */
  .medium-hidden {
    display: none !important; }
  .medium-visible {
    display: block !important; }
  .medium-no-float {
    float: none; }
  .medium-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for medium screens */
  .medium-w25 {
    width: 25% !important; }
  .medium-w33 {
    width: 33.333333% !important; }
  .medium-w50 {
    width: 50% !important; }
  .medium-w66 {
    width: 66.666666% !important; }
  .medium-w75 {
    width: 75% !important; }
  .medium-w100,
  .medium-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for medium screens */
  .medium-man,
  .medium-ma0 {
    margin: 0 !important; } }

/* small screens */
/* ------------- */
@media (min-width: 576px) and (max-width: 767px) {
  /* layouts for small screens */
  .small-hidden {
    display: none !important; }
  .small-visible {
    display: block !important; }
  .small-no-float {
    float: none; }
  .small-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for small screens */
  .small-w25 {
    width: 25% !important; }
  .small-w33 {
    width: 33.333333% !important; }
  .small-w50 {
    width: 50% !important; }
  .small-w66 {
    width: 66.666666% !important; }
  .small-w75 {
    width: 75% !important; }
  .small-w100,
  .small-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for small screens */
  .small-man,
  .small-ma0 {
    margin: 0 !important; }
  .small-pan,
  .small-pa0 {
    padding: 0 !important; } }

/* tiny screens */
/* ------------ */
@media (max-width: 575px) {
  /* quick small resolution reset */
  .mod,
  .col,
  fieldset {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* layouts for tiny screens */
  .tiny-hidden {
    display: none !important; }
  .tiny-visible {
    display: block !important; }
  .tiny-no-float {
    float: none; }
  .tiny-inbl {
    display: inline-block;
    float: none;
    vertical-align: top; }
  /* widths for tiny screens */
  .tiny-w25 {
    width: 25% !important; }
  .tiny-w33 {
    width: 33.333333% !important; }
  .tiny-w50 {
    width: 50% !important; }
  .tiny-w66 {
    width: 66.666666% !important; }
  .tiny-w75 {
    width: 75% !important; }
  .tiny-w100,
  .tiny-wauto {
    display: block !important;
    float: none !important;
    clear: none !important;
    width: auto !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0; }
  /* margins for tiny screens */
  .tiny-man,
  .tiny-ma0 {
    margin: 0 !important; }
  .tiny-pan,
  .tiny-pa0 {
    padding: 0 !important; } }

/* --------------------------------------- */
/* ==Grillade : ultra light Grid System    */
/* --------------------------------------- */
@media (min-width: 480px) {
  [class*=" grid-"],
  [class^="grid-"] {
    display: grid;
    grid-auto-flow: dense; }
    [class*=" grid-"].has-gutter,
    [class^="grid-"].has-gutter {
      grid-gap: 1rem; }
    [class*=" grid-"].has-gutter-l,
    [class^="grid-"].has-gutter-l {
      grid-gap: 2rem; }
    [class*=" grid-"].has-gutter-xl,
    [class^="grid-"].has-gutter-xl {
      grid-gap: 4rem; } }

@media (min-width: 480px) {
  .autogrid,
  .grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr; }
    .autogrid.has-gutter,
    .grid.has-gutter {
      grid-column-gap: 1rem; }
    .autogrid.has-gutter-l,
    .grid.has-gutter-l {
      grid-column-gap: 2rem; }
    .autogrid.has-gutter-xl,
    .grid.has-gutter-xl {
      grid-column-gap: 4rem; } }

[class*="grid-2"] {
  grid-template-columns: repeat(2, 1fr); }

[class*="grid-3"] {
  grid-template-columns: repeat(3, 1fr); }

[class*="grid-4"] {
  grid-template-columns: repeat(4, 1fr); }

[class*="grid-5"] {
  grid-template-columns: repeat(5, 1fr); }

[class*="grid-6"] {
  grid-template-columns: repeat(6, 1fr); }

[class*="grid-7"] {
  grid-template-columns: repeat(7, 1fr); }

[class*="grid-8"] {
  grid-template-columns: repeat(8, 1fr); }

[class*="grid-9"] {
  grid-template-columns: repeat(9, 1fr); }

[class*="grid-10"] {
  grid-template-columns: repeat(10, 1fr); }

[class*="grid-11"] {
  grid-template-columns: repeat(11, 1fr); }

[class*="grid-12"] {
  grid-template-columns: repeat(12, 1fr); }

[class*="col-1"] {
  grid-column: auto/span 1; }

[class*="row-1"] {
  grid-row: auto/span 1; }

[class*="col-2"] {
  grid-column: auto/span 2; }

[class*="row-2"] {
  grid-row: auto/span 2; }

[class*="col-3"] {
  grid-column: auto/span 3; }

[class*="row-3"] {
  grid-row: auto/span 3; }

[class*="col-4"] {
  grid-column: auto/span 4; }

[class*="row-4"] {
  grid-row: auto/span 4; }

[class*="col-5"] {
  grid-column: auto/span 5; }

[class*="row-5"] {
  grid-row: auto/span 5; }

[class*="col-6"] {
  grid-column: auto/span 6; }

[class*="row-6"] {
  grid-row: auto/span 6; }

[class*="col-7"] {
  grid-column: auto/span 7; }

[class*="row-7"] {
  grid-row: auto/span 7; }

[class*="col-8"] {
  grid-column: auto/span 8; }

[class*="row-8"] {
  grid-row: auto/span 8; }

[class*="col-9"] {
  grid-column: auto/span 9; }

[class*="row-9"] {
  grid-row: auto/span 9; }

[class*="col-10"] {
  grid-column: auto/span 10; }

[class*="row-10"] {
  grid-row: auto/span 10; }

[class*="col-11"] {
  grid-column: auto/span 11; }

[class*="row-11"] {
  grid-row: auto/span 11; }

[class*="col-12"] {
  grid-column: auto/span 12; }

[class*="row-12"] {
  grid-row: auto/span 12; }

/* intermediate breakpoints */
@media (min-width: 480px) and (max-width: 767px) {
  [class*="grid-"][class*="-small-1"] {
    grid-template-columns: repeat(1, 1fr); }
  [class*="col-"][class*="-small-1"] {
    grid-column: auto/span 1; }
  [class*="grid-"][class*="-small-2"] {
    grid-template-columns: repeat(2, 1fr); }
  [class*="col-"][class*="-small-2"] {
    grid-column: auto/span 2; }
  [class*="grid-"][class*="-small-3"] {
    grid-template-columns: repeat(3, 1fr); }
  [class*="col-"][class*="-small-3"] {
    grid-column: auto/span 3; }
  [class*="grid-"][class*="-small-4"] {
    grid-template-columns: repeat(4, 1fr); }
  [class*="col-"][class*="-small-4"] {
    grid-column: auto/span 4; }
  [class*="-small-all"] {
    grid-column: 1 / -1; } }

.item-first {
  order: -1; }

.item-last {
  order: 1; }

.grid-offset {
  visibility: hidden; }

.col-all {
  grid-column: 1 / -1; }

.row-all {
  grid-row: 1 / -1; }

/* ----------------------------- */
/* ==Media object                */
/* ----------------------------- */
/* recommanded HTML : <div class="media"><img class="media-figure"><div class="media-content"></div></div> */
/* see http://codepen.io/raphaelgoetter/pen/KMWWwj */
@media (min-width: 480px) {
  .media {
    display: flex;
    align-items: flex-start; }
    .media-content {
      flex: 1 1 0%; }
    .media-figure--center {
      align-self: center; }
    .media--reverse {
      flex-direction: row-reverse; } }

/* ----------------------------- */
/* ==skip links                  */
/* ----------------------------- */
/* see https://www.alsacreations.com/article/lire/572-Les-liens-d-evitement.html */
/* styling skip links */
.skip-links {
  position: absolute; }
  .skip-links a {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0.5em;
    background: black;
    color: white;
    text-decoration: none; }
    .skip-links a:focus {
      position: static;
      overflow: visible;
      clip: auto; }

/* ----------------------------- */
/* ==Tables                      */
/* ----------------------------- */
table,
.table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: 2rem; }

.table {
  display: table;
  border: 1px solid #acb3c2;
  background: transparent; }
  .table--zebra tbody tr:nth-child(odd) {
    background: #e7e9ed; }
  .table caption {
    caption-side: bottom;
    padding: 1rem;
    color: #333;
    font-style: italic;
    text-align: right; }
  .table td,
  .table th {
    padding: 0.3rem 0.6rem;
    min-width: 2rem;
    vertical-align: top;
    border: 1px #acb3c2 dotted;
    text-align: left;
    cursor: default; }
  .table thead {
    color: #212529;
    background: transparent; }

.table--auto {
  table-layout: auto; }

/* ----------------------------- */
/* ==Forms                       */
/* ----------------------------- */
/* thanks to HTML5boilerplate and https://shoelace.style/ */
/* forms items */
form,
fieldset {
  border: none; }

fieldset {
  padding: 2rem; }
  fieldset legend {
    padding: 0 0.5rem;
    border: 0;
    white-space: normal; }

label {
  display: inline-block;
  cursor: pointer; }

[type="color"],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  box-shadow: 0 0 0 1px #333 inset;
  color: #212529;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  margin: 0;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  appearance: none; }

[type="submit"] {
  background-color: #333;
  color: #fff;
  cursor: pointer; }

input[readonly] {
  background-color: #e7e9ed; }

select {
  padding-right: 2rem;
  border-radius: 0;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
  background-position: right .6rem center;
  background-repeat: no-repeat;
  background-size: 1.2rem; }

/* hiding IE11 arrow */
select::-ms-expand {
  display: none; }

textarea {
  min-height: 5em;
  vertical-align: top;
  resize: vertical;
  white-space: normal; }

/* 'x' appears on right of search input when text is entered. This removes it */
[type="search"]::-webkit-search-decoration, [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-results-button, [type="search"]::-webkit-search-results-decoration {
  display: none; }

::-webkit-input-placeholder {
  color: #777; }

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #777; }

progress {
  width: 100%;
  vertical-align: middle; }

/* ----------------------------- */
/* ==Buttons                     */
/* ----------------------------- */
/* preferably use <button> for buttons !*/
/* use .btn-- or .button-- classes for variants */
.btn,
.button,
[type="button"],
button, .btn--primary,
.button--primary, .btn--success,
.button--success, .btn--info,
.button--info, .btn--warning,
.button--warning, .btn--danger,
.button--danger, .btn--inverse,
.button--inverse, .btn--ghost,
.button--ghost {
  display: inline-block;
  padding: 1rem 1.5rem;
  cursor: pointer;
  user-select: none;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: #212529;
  border: none;
  border-radius: 0;
  background-color: #e7e9ed;
  font-family: inherit;
  font-size: inherit;
  line-height: 1; }

.btn:focus,
.button:focus,
[type="button"]:focus,
button:focus {
  -webkit-tap-highlight-color: transparent; }

.btn--primary,
.button--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }
  .btn--primary:active, .btn--primary:focus, .btn--primary:hover,
  .button--primary:active,
  .button--primary:focus,
  .button--primary:hover {
    background-color: #025aa5; }

.btn--success,
.button--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }
  .btn--success:active, .btn--success:focus, .btn--success:hover,
  .button--success:active,
  .button--success:focus,
  .button--success:hover {
    background-color: #449d44; }

.btn--info,
.button--info {
  background-color: #5BC0DE;
  color: #000;
  box-shadow: none; }
  .btn--info:active, .btn--info:focus, .btn--info:hover,
  .button--info:active,
  .button--info:focus,
  .button--info:hover {
    background-color: #31b0d5; }

.btn--warning,
.button--warning {
  background-color: #F0AD4E;
  color: #000;
  box-shadow: none; }
  .btn--warning:active, .btn--warning:focus, .btn--warning:hover,
  .button--warning:active,
  .button--warning:focus,
  .button--warning:hover {
    background-color: #ec971f; }

.btn--danger,
.button--danger {
  background-color: #D9534F;
  color: #fff;
  box-shadow: none; }
  .btn--danger:active, .btn--danger:focus, .btn--danger:hover,
  .button--danger:active,
  .button--danger:focus,
  .button--danger:hover {
    background-color: #c9302c; }

.btn--inverse,
.button--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }
  .btn--inverse:active, .btn--inverse:focus, .btn--inverse:hover,
  .button--inverse:active,
  .button--inverse:focus,
  .button--inverse:hover {
    background-color: #1a1a1a; }

.btn--ghost,
.button--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }
  .btn--ghost:active, .btn--ghost:focus, .btn--ghost:hover,
  .button--ghost:active,
  .button--ghost:focus,
  .button--ghost:hover {
    background-color: transparent; }

.btn--small,
.button--small {
  padding: 0.7rem 1rem;
  font-size: .8em; }

.btn--big,
.button--big {
  padding: 1.5rem 2rem;
  font-size: 1.4em; }

.btn--block,
.button--block {
  width: 100% !important;
  display: block; }

.btn--unstyled,
.button--unstyled {
  padding: 0;
  border: none;
  text-align: left;
  background: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .btn--unstyled:focus,
  .button--unstyled:focus {
    box-shadow: none;
    outline: none; }

.nav-button {
  padding: 0;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }
  .nav-button > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 2.6rem;
    width: 2.6rem;
    padding: 0;
    background-color: transparent;
    background-image: linear-gradient(#333, #333);
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 100% 5px;
    transition: .25s;
    transition-property: transform, background;
    will-change: transform, background; }
    .nav-button > *::before, .nav-button > *::after {
      content: "";
      height: 5px;
      background: #333;
      transition: .25s;
      transition-property: transform, top;
      will-change: transform, top; }
  .nav-button:hover > * {
    background-color: transparent; }
  .nav-button:focus {
    outline: 0; }
  .nav-button.is-active > * {
    background-image: none;
    justify-content: center; }
    .nav-button.is-active > *::before {
      transform: translateY(50%) rotate3d(0, 0, 1, 45deg); }
    .nav-button.is-active > *::after {
      transform: translateY(-50%) rotate3d(0, 0, 1, -45deg); }

/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommanded HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommanded HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
.checkbox {
  border-radius: 4px; }

.switch {
  border-radius: 3em; }

.radio {
  border-radius: 50%; }

.switch,
.checkbox,
.radio {
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #333;
  background: #fff; }
  .switch ~ label,
  .checkbox ~ label,
  .radio ~ label {
    cursor: pointer; }
  .switch::-ms-check,
  .checkbox::-ms-check,
  .radio::-ms-check {
    display: none; }

.switch {
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 60%;
  box-shadow: inset -2rem 0 0 #333, inset 0 0 0 1px #333;
  transition: box-shadow .15s; }
  .switch::before, .switch::after {
    font-weight: bold;
    color: #fff; }
  .switch::before {
    content: "✕";
    float: right;
    margin-right: 0.66667rem; }
  .switch:checked {
    box-shadow: inset 2rem 0 0 #5CB85C, inset 0 0 0 1px #5CB85C; }
    .switch:checked::before {
      content: "✓";
      float: left;
      margin-left: 0.66667rem; }

.checkbox {
  width: 2rem;
  height: 2rem;
  transition: background-color .15s; }
  .checkbox:checked {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    background-size: 60% 60%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #333; }

.radio {
  width: 2rem;
  height: 2rem;
  transition: background-color .15s; }
  .radio:checked {
    background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%2240%22%20height%3D%2240%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23ffffff%22/%3E%3C/svg%3E);
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #333; }

/* ----------------------------- */
/* ==Tabs                        */
/* ----------------------------- */
/* see example on https://knacss.com/styleguide.html#tabs */
/* NOTE : tabs need JavaScript to be activated */
.tabs-menu {
  border-bottom: 2px solid #e7e9ed; }
  .tabs-menu-link {
    display: block;
    margin-bottom: -2px;
    padding: 0.5rem 3rem;
    border-bottom: 4px solid transparent;
    color: #212529;
    background: transparent;
    text-decoration: none;
    border-radius: 0 0 0 0;
    transition: .25s;
    transition-property: color, border, background-color; }
    .tabs-menu-link.is-active {
      border-bottom-color: #333;
      color: #333;
      background: transparent;
      outline: 0; }
    .tabs-menu-link:focus {
      border-bottom-color: #333;
      color: #333;
      outline: 0; }
    @media (min-width: 576px) {
      .tabs-menu-link {
        display: inline-block; } }

.tabs-content-item {
  padding-top: 1rem; }
  .tabs-content-item[aria-hidden="true"] {
    visibility: hidden; }
  .tabs-content-item[aria-hidden="false"] {
    visibility: visible; }

/* ----------------------------- */
/* ==Arrows                      */
/* ----------------------------- */
/* see https://knacss.com/styleguide.html#arrows */
[class*="icon-arrow--"] {
  vertical-align: middle; }
  [class*="icon-arrow--"]::after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    mask-size: cover;
    background-color: #000;
    line-height: 1; }

.icon-arrow--down::after {
  mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--up::after {
  mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%208.596%20L%203.523%2014.973%20C%202.464%2015.935%200.495%2013.72%201.505%2012.791%20L%201.505%2012.791%20L%208.494%205.799%20C%209.138%205.212%2010.655%205.193%2011.29%205.799%20L%2011.29%205.799%20L%2018.49%2012.791%20C%2019.557%2013.809%2017.364%2015.882%2016.262%2014.837%20L%2016.262%2014.837%20L%209.96%208.596%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--right::after {
  mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%2011.685%2010.321%20L%205.308%2016.758%20C%204.346%2017.817%206.561%2019.786%207.49%2018.776%20L%207.49%2018.776%20L%2014.482%2011.787%20C%2015.069%2011.142%2015.088%209.626%2014.482%208.991%20L%2014.482%208.991%20L%207.49%201.791%20C%206.472%200.724%204.399%202.916%205.444%204.019%20L%205.444%204.019%20L%2011.685%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

.icon-arrow--left::after {
  mask-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%208.315%2010.321%20L%2014.692%2016.758%20C%2015.654%2017.817%2013.439%2019.786%2012.51%2018.776%20L%2012.51%2018.776%20L%205.518%2011.787%20C%204.931%2011.142%204.912%209.626%205.518%208.991%20L%205.518%208.991%20L%2012.51%201.791%20C%2013.528%200.724%2015.601%202.916%2014.556%204.019%20L%2014.556%204.019%20L%208.315%2010.321%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E"); }

/* ----------------------------- */
/* ==Tags                      */
/* ----------------------------- */
/* use .tag-- classes for variants */
.tag, .tag--primary, .tag--success, .tag--info, .tag--warning, .tag--danger, .tag--inverse, .tag--ghost {
  display: inline-block;
  padding: 3px 0.5rem;
  vertical-align: baseline;
  white-space: nowrap;
  color: #212529;
  border-radius: 0;
  background-color: #e7e9ed;
  line-height: 1; }

.tag--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }

.tag--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }

.tag--info {
  background-color: #5BC0DE;
  color: #000;
  box-shadow: none; }

.tag--warning {
  background-color: #F0AD4E;
  color: #000;
  box-shadow: none; }

.tag--danger {
  background-color: #D9534F;
  color: #fff;
  box-shadow: none; }

.tag--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }

.tag--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }

.tag--small {
  font-size: 1.2rem; }

.tag--big {
  font-size: 2rem; }

.tag--block {
  width: 100% !important;
  display: block; }

.tag.disabled, .disabled.tag--primary, .disabled.tag--success, .disabled.tag--info, .disabled.tag--warning, .disabled.tag--danger, .disabled.tag--inverse, .disabled.tag--ghost, .tag--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.tag:empty, .tag--primary:empty, .tag--success:empty, .tag--info:empty, .tag--warning:empty, .tag--danger:empty, .tag--inverse:empty, .tag--ghost:empty {
  display: none; }

/* ----------------------------- */
/* ==Badges                      */
/* ----------------------------- */
/* use .badge-- classes for variants */
.badge, .badge--primary, .badge--success, .badge--info, .badge--warning, .badge--danger, .badge--inverse, .badge--ghost {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 50%;
  color: #212529;
  background-color: #e7e9ed;
  line-height: 1; }
  .badge::before, .badge--primary::before, .badge--success::before, .badge--info::before, .badge--warning::before, .badge--danger::before, .badge--inverse::before, .badge--ghost::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%; }

.badge--primary {
  background-color: #0275D8;
  color: #fff; }

.badge--success {
  background-color: #5CB85C;
  color: #fff; }

.badge--info {
  background-color: #5BC0DE;
  color: #000; }

.badge--warning {
  background-color: #F0AD4E;
  color: #000; }

.badge--danger {
  background-color: #D9534F;
  color: #fff; }

.badge--inverse {
  background-color: #333;
  color: #fff; }

.badge--ghost {
  background-color: transparent;
  color: #fff; }

.badge--small {
  font-size: 1.2rem; }

.badge--big {
  font-size: 2rem; }

.badge.disabled, .disabled.badge--primary, .disabled.badge--success, .disabled.badge--info, .disabled.badge--warning, .disabled.badge--danger, .disabled.badge--inverse, .disabled.badge--ghost, .badge--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.badge:empty, .badge--primary:empty, .badge--success:empty, .badge--info:empty, .badge--warning:empty, .badge--danger:empty, .badge--inverse:empty, .badge--ghost:empty {
  display: none; }

/* ----------------------------- */
/* ==Alerts                      */
/* ----------------------------- */
/* use .alert-- classes for variants */
.alert, .alert--primary, .alert--success, .alert--info, .alert--warning, .alert--danger, .alert--inverse, .alert--ghost {
  padding: 1rem 1rem;
  margin-top: 0.75em;
  margin-bottom: 0;
  color: #212529;
  border-radius: 0;
  background-color: #e7e9ed; }
  .alert a, .alert--primary a, .alert--success a, .alert--info a, .alert--warning a, .alert--danger a, .alert--inverse a, .alert--ghost a {
    color: inherit;
    text-decoration: underline; }

.alert--primary {
  background-color: #0275D8;
  color: #fff;
  box-shadow: none; }

.alert--success {
  background-color: #5CB85C;
  color: #fff;
  box-shadow: none; }

.alert--info {
  background-color: #5BC0DE;
  color: #000;
  box-shadow: none; }

.alert--warning {
  background-color: #F0AD4E;
  color: #000;
  box-shadow: none; }

.alert--danger {
  background-color: #D9534F;
  color: #fff;
  box-shadow: none; }

.alert--inverse {
  background-color: #333;
  color: #fff;
  box-shadow: none; }

.alert--ghost {
  background-color: transparent;
  color: #fff;
  box-shadow: 0 0 0 1px #fff inset; }

.alert--small {
  font-size: 1.2rem; }

.alert--big {
  font-size: 2rem; }

.alert--block {
  width: 100% !important;
  display: block; }

.alert.disabled, .disabled.alert--primary, .disabled.alert--success, .disabled.alert--info, .disabled.alert--warning, .disabled.alert--danger, .disabled.alert--inverse, .disabled.alert--ghost, .alert--disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.alert:empty, .alert--primary:empty, .alert--success:empty, .alert--info:empty, .alert--warning:empty, .alert--danger:empty, .alert--inverse:empty, .alert--ghost:empty {
  display: none; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1rem 1rem;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 3rem;
  height: 2.2rem;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.1rem; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 3rem;
    height: 0.2rem;
    background-color: #d4a008;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -1rem; }
  .hamburger-inner::after {
    bottom: -1rem; }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 1; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 1.5rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 2rem;
    width: 2rem;
    margin: 0 .5rem;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 1.4rem;
      width: 1.4rem;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: .5rem;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        border-radius: .7rem;
        border: .2rem solid #fff;
        transition: all .2s ease-in-out; }
    .slick-dots li.slick-active button:before {
      background-color: #fff; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #21201f;
  opacity: 0.9; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

html {
  display: flex;
  flex-direction: column; }

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font: 300 1.5rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #21201f;
  background-color: #f7f3e9; }
  body.is-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden; }

main {
  flex: 1 1 auto;
  width: 100%;
  background-color: #fff; }
  main.container {
    margin-top: 15.5rem;
    padding: 2rem; }
    @media (max-width: 992px) {
      main.container {
        margin-top: 10.4rem; } }
    @media (max-width: 700px) {
      main.container {
        margin-top: 7.4rem; } }
  main.is-scroll {
    margin-top: 13.5rem; }
    @media (max-width: 992px) {
      main.is-scroll {
        margin-top: 8.4rem; } }
    @media (max-width: 700px) {
      main.is-scroll {
        margin-top: 5.4rem; } }

h1 {
  font: 700 5rem "Garamond", Georgia, serif; }
  @media (max-width: 768px) {
    h1 {
      font-size: 3.5rem; } }
  @media (max-width: 480px) {
    h1 {
      font-size: 2.5rem; } }
  h1 span {
    font-weight: 500;
    font-size: 3.5rem; }
    @media (max-width: 768px) {
      h1 span {
        font-size: 2.8rem; } }
    @media (max-width: 480px) {
      h1 span {
        font-size: 2rem; } }

h2 {
  margin-bottom: 2rem;
  font: 500 3.5rem "Garamond", Georgia, serif; }
  @media (max-width: 768px) {
    h2 {
      font-size: 2.9rem; } }
  @media (max-width: 576px) {
    h2 {
      margin-bottom: 1.5rem; } }
  @media (max-width: 480px) {
    h2 {
      font-size: 2.5rem; } }

h3 {
  margin-bottom: 1.5rem;
  font: 400 2.1rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media (max-width: 768px) {
    h3 {
      font-size: 1.9rem; } }
  @media (max-width: 480px) {
    h3 {
      font-size: 1.7rem; } }

.slides-wrapper .slick-list, .slides-wrapper .slick-track, .slides-wrapper .slick-slide, .slides-wrapper .slick-slide div, .slides-wrapper .slide, .slides-wrapper .slide-link {
  height: 100%; }

.container, .container-small, .container-tiny {
  position: relative;
  margin: 0 auto;
  padding: 0 2rem; }
  @media (max-width: 576px) {
    .container, .container-small, .container-tiny {
      padding: 0 1.5rem; } }

.container {
  max-width: 124rem; }

.container-small {
  max-width: 94rem; }
  @media (max-width: 768px) {
    .container-small {
      padding: 0; } }

.container-tiny {
  max-width: 75rem; }
  @media (max-width: 768px) {
    .container-tiny {
      padding: 0; } }

.p-section {
  padding-top: 4rem;
  padding-bottom: 7rem; }
  @media (max-width: 768px) {
    .p-section {
      padding-top: 3rem;
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .p-section {
      padding-bottom: 2rem; } }

.page-entete .slides-wrapper, .page-entete .page-entete__image, .page-entete .map {
  position: relative;
  width: 100%;
  max-height: 70rem;
  height: calc(100vh - 19.5rem); }
  @media (max-width: 992px) {
    .page-entete .slides-wrapper, .page-entete .page-entete__image, .page-entete .map {
      max-height: 50rem; } }
  @media (max-width: 768px) {
    .page-entete .slides-wrapper, .page-entete .page-entete__image, .page-entete .map {
      max-height: 40rem; } }
  @media (max-width: 576px) {
    .page-entete .slides-wrapper, .page-entete .page-entete__image, .page-entete .map {
      max-height: 30rem; } }
  @media (max-width: 480px) {
    .page-entete .slides-wrapper, .page-entete .page-entete__image, .page-entete .map {
      max-height: 20rem; } }

.page-entete .slide {
  height: 100%; }

.page-entete h1 {
  margin: 0;
  text-align: center; }

.page-entete .bloc-texte p:first-of-type {
  margin-top: 3rem; }
  @media (max-width: 576px) {
    .page-entete .bloc-texte p:first-of-type {
      margin-top: 2rem; } }

.page-entete .bloc-texte p:last-of-type {
  margin-bottom: 0; }

.page-entete .bloc-texte p:not(.news-date):not(.oeuvres-categories):not(.oeuvre-intro):not(.fil-ariane) {
  font-size: 1.8rem; }
  @media (max-width: 576px) {
    .page-entete .bloc-texte p:not(.news-date):not(.oeuvres-categories):not(.oeuvre-intro):not(.fil-ariane) {
      font-size: 1.6rem; } }
  .page-entete .bloc-texte p:not(.news-date):not(.oeuvres-categories):not(.oeuvre-intro):not(.fil-ariane):first-of-type:first-letter {
    font: 500 3rem "Garamond", Georgia, serif;
    font-style: italic; }
    @media (max-width: 576px) {
      .page-entete .bloc-texte p:not(.news-date):not(.oeuvres-categories):not(.oeuvre-intro):not(.fil-ariane):first-of-type:first-letter {
        font-size: 2rem; } }

.page-entete .news-date {
  margin: 0;
  font: 500 1.5rem "Garamond", Georgia, serif;
  font-style: italic;
  text-align: center; }
  @media (max-width: 768px) {
    .page-entete .news-date {
      font-size: 1.3rem; } }

.page-entete .oeuvres-categories {
  margin: 2rem 0 0;
  font-size: 1.5rem; }
  @media (max-width: 768px) {
    .page-entete .oeuvres-categories {
      font-size: 1.3rem; } }
  .page-entete .oeuvres-categories a {
    display: inline-block;
    padding: 0 .5rem .1rem;
    margin: .5rem .25rem 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    background-color: #21201f;
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
    .page-entete .oeuvres-categories a:hover {
      background-color: #d4a008; }

.page-entete .oeuvre-intro {
  margin: 0;
  font: 500 2rem "Garamond", Georgia, serif;
  font-style: italic;
  text-align: center; }

.page-entete .fil-ariane {
  font-size: 1.3rem;
  text-align: center;
  color: rgba(33, 32, 31, 0.7); }
  @media (max-width: 768px) {
    .page-entete .fil-ariane {
      margin-top: 1rem !important;
      margin-bottom: 0; } }
  .page-entete .fil-ariane a {
    color: inherit; }

.page-entete .tags-wrapper {
  margin: 3rem -.5rem 0;
  text-align: center; }
  .page-entete .tags-wrapper a {
    display: inline-block;
    margin: .5rem .5rem 0;
    padding: .2rem .7rem .4rem;
    font-size: 1.6rem;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #d4a008;
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
    @media (max-width: 480px) {
      .page-entete .tags-wrapper a {
        font-size: 1.4rem; } }
    .page-entete .tags-wrapper a.tags-close {
      width: 3rem;
      background-color: #c31818; }
    .page-entete .tags-wrapper a.button-active {
      background-color: #21201f; }
    .page-entete .tags-wrapper a:hover {
      background-color: #21201f; }

a.basic-btn, button.basic-btn {
  display: table;
  margin: 3rem auto 0;
  padding: 1.5rem 2rem;
  font: 700 2rem "Garamond", Georgia, serif;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  box-shadow: none;
  outline: none;
  color: #fff;
  background-color: #d4a008;
  transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
  @media (max-width: 576px) {
    a.basic-btn, button.basic-btn {
      font-size: 1.8rem; } }
  a.basic-btn:hover, button.basic-btn:hover {
    background-color: #21201f; }

.pagination {
  width: 100%;
  margin: 3rem 0 1rem;
  font-weight: 400;
  text-align: center; }
  @media (max-width: 576px) {
    .pagination {
      margin-top: 2rem; } }
  .pagination span, .pagination a {
    display: inline-block;
    width: 4rem;
    height: 4rem; }
    @media (max-width: 480px) {
      .pagination span, .pagination a {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.3rem; } }
  .pagination .current {
    padding: 1rem;
    color: #fff;
    background-color: #21201f; }
    @media (max-width: 480px) {
      .pagination .current {
        padding: .5rem; } }
  .pagination a {
    padding: 1rem;
    text-decoration: none;
    background-color: rgba(212, 160, 8, 0.3);
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
    @media (max-width: 480px) {
      .pagination a {
        padding: .5rem; } }
    .pagination a:hover {
      color: #fff;
      background-color: #d4a008; }

.mentions-legales, .plan-du-site, .erreur-404, .erreur-500 {
  padding-bottom: 8rem; }
  @media (max-width: 768px) {
    .mentions-legales, .plan-du-site, .erreur-404, .erreur-500 {
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .mentions-legales, .plan-du-site, .erreur-404, .erreur-500 {
      padding-bottom: 2rem; } }
  .mentions-legales h2:not(:first-of-type), .plan-du-site h2:not(:first-of-type), .erreur-404 h2:not(:first-of-type), .erreur-500 h2:not(:first-of-type) {
    margin-top: 3rem; }

.plan-du-site .page--sitemap__first {
  padding: 0; }
  .plan-du-site .page--sitemap__first li {
    list-style-type: none; }
    .plan-du-site .page--sitemap__first li a {
      display: inline-block;
      padding: .5rem;
      text-decoration: none;
      transition: color 1s cubic-bezier(0.19, 1, 0.22, 1); }
      .plan-du-site .page--sitemap__first li a:hover {
        color: #d4a008; }
  .plan-du-site .page--sitemap__first > li {
    text-transform: uppercase;
    font-weight: 400; }
  .plan-du-site .page--sitemap__first > ul li {
    font-weight: 400; }
    .plan-du-site .page--sitemap__first > ul li > ul li {
      font-weight: 300; }

.recherche-content {
  padding-bottom: 8rem; }

header {
  position: fixed;
  width: 100%;
  background-color: #f7f3e9;
  z-index: 4; }
  header > .container {
    flex-direction: column;
    border-bottom: 2rem solid #fff; }
    @media (max-width: 992px) {
      header > .container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
  header.is-mobile-nav-open .header__links {
    pointer-events: auto;
    opacity: 1; }
  header .header__logo {
    display: block;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center; }
    @media (max-width: 700px) {
      header .header__logo {
        padding: .5rem; } }
  header .logo-richard-apprederis {
    width: 14rem;
    height: 7rem;
    fill: #21201f;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
    @media (max-width: 700px) {
      header .logo-richard-apprederis {
        width: 10rem;
        height: 5rem; } }
    header .logo-richard-apprederis:hover {
      fill: #d4a008; }
  header.is-scroll .logo-richard-apprederis {
    width: 10rem;
    height: 5rem; }
    @media (max-width: 700px) {
      header.is-scroll .logo-richard-apprederis {
        width: 8rem;
        height: 4rem; } }
  @media (max-width: 700px) {
    header .header__recherche {
      display: none; } }
  header .header__recherche .header__recherche-input {
    width: 18rem;
    background-color: transparent;
    transition: all .3s ease-in-out; }
    header .header__recherche .header__recherche-input:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    header .header__recherche .header__recherche-input:focus {
      box-shadow: 0 0 0 0.2rem #21201f inset;
      background-color: #fff; }
  header .links-wrapper {
    align-items: center;
    justify-content: space-between;
    margin: 0 -1rem 1.5rem 0; }
    @media (max-width: 992px) {
      header .links-wrapper {
        margin: 0; } }
  header .header__recherche-group {
    align-items: center; }
    header .header__recherche-group .loupe {
      margin-left: 1rem;
      fill: #d4a008; }
  @media (max-width: 992px) {
    header .header__links {
      flex-wrap: nowrap;
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      height: calc(100vh - 10.4rem);
      padding: 1.5rem;
      pointer-events: none;
      text-align: center;
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.95);
      transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1); } }
  @media (max-width: 700px) {
    header .header__links {
      height: calc(100vh - 7.4rem); } }
  @media (max-width: 480px) {
    header .header__links {
      justify-content: flex-start; } }
  header .categories-drop-down {
    position: relative; }
    header .categories-drop-down .nav-link:after {
      content: none; }
    @media (min-width: 993px) {
      header .categories-drop-down:hover .nav-categories-wrapper {
        pointer-events: auto; }
        header .categories-drop-down:hover .nav-categories-wrapper:before {
          width: 100%; }
        header .categories-drop-down:hover .nav-categories-wrapper .nav-sub-link {
          opacity: 1; } }
  header .nav-categories-wrapper {
    position: relative; }
    @media (min-width: 993px) {
      header .nav-categories-wrapper {
        position: absolute;
        top: 3.3rem;
        left: 50%;
        pointer-events: none;
        transform: translateX(-50%); } }
    header .nav-categories-wrapper:before {
      position: absolute;
      left: 50%;
      top: -.2rem;
      height: .2rem;
      width: 0;
      content: '';
      background-color: #d4a008;
      transform: translateX(-50%);
      transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1); }
  header .nav-sub-link {
    display: block;
    padding: 1.2rem 2rem 1rem;
    font: 400 1.4rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 1s cubic-bezier(0.19, 1, 0.22, 1), background-color 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 1s cubic-bezier(0.19, 1, 0.22, 1) 0.3s; }
    @media (min-width: 993px) {
      header .nav-sub-link {
        padding: 1rem;
        background-color: #fff;
        opacity: 0; }
        header .nav-sub-link:hover {
          color: #fff;
          background-color: #21201f; } }
    @media (max-width: 992px) {
      header .nav-sub-link {
        font-size: 1.3rem; } }
    @media (max-width: 480px) {
      header .nav-sub-link {
        padding: .5rem 1rem; } }
  header .nav-link {
    display: inline-block;
    position: relative;
    margin: 0 1rem;
    padding: .8rem 1rem;
    font: 700 2rem "Garamond", Georgia, serif;
    text-decoration: none;
    transition: color .3s ease-in-out; }
    @media (min-width: 993px) {
      header .nav-link:after {
        position: absolute;
        left: 50%;
        bottom: .3rem;
        height: .2rem;
        width: 0;
        content: '';
        background-color: #d4a008;
        transform: translateX(-50%);
        transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1); }
      header .nav-link:hover:after, header .nav-link.is-active:after {
        width: calc(100% - 5rem); } }
    @media (max-width: 992px) {
      header .nav-link {
        font-size: 2.2rem; } }
    @media (max-width: 480px) {
      header .nav-link {
        padding: .5rem 1rem;
        font-size: 2rem; } }
    header .nav-link.is-active {
      color: #d4a008; }
    @media (min-width: 993px) {
      header .nav-link.nav-link-contact-small {
        display: none; } }
    header .nav-link.nav-link-contact {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #fff;
      background-color: #d4a008;
      transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
      @media (max-width: 992px) {
        header .nav-link.nav-link-contact {
          display: none; } }
      header .nav-link.nav-link-contact:after {
        content: none; }
      header .nav-link.nav-link-contact:hover {
        background-color: #21201f; }
    header .nav-link.is-active-contact {
      background-color: #21201f; }
  header .hamburger {
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    outline: none; }
    @media (min-width: 993px) {
      header .hamburger {
        display: none; } }
    header .hamburger .hamburger-label {
      margin: .3rem 1.2rem 0 0;
      text-transform: uppercase;
      font: 700 2rem "Garamond", Georgia, serif;
      color: #d4a008; }

.autocomplete-suggestion {
  display: flex; }
  .autocomplete-suggestion:hover .autocomplete-suggestion-contenu {
    color: #fff;
    background-color: #21201f; }
  .autocomplete-suggestion .autocomplete-suggestion-contenu {
    padding: 0 .5rem;
    cursor: pointer;
    background-color: #fff;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
    .autocomplete-suggestion .autocomplete-suggestion-contenu span {
      width: 100%; }
    .autocomplete-suggestion .autocomplete-suggestion-contenu .recherche-produit {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.5rem; }
    .autocomplete-suggestion .autocomplete-suggestion-contenu .recherche-categorie {
      font-size: 1.3rem;
      font-style: italic; }

@font-face {
  font-family: 'slick';
  src: url("../fonts/slick/slick.woff2") format("woff2"), url("../fonts/slick/slick.woff") format("woff"), url("../fonts/slick/slick.ttf") format("truetype"), url("../fonts/slick/slick.svg#Slick") format("svg"); }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/garamond/garamond-bold.woff2") format("woff2"), url("../fonts/garamond/garamond-bold.woff") format("woff"), url("../fonts/garamond/garamond-bold.ttf") format("truetype"), url("../fonts/garamond/garamond-bold.svg#GaramondBold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/garamond/garamond-regular.woff2") format("woff2"), url("../fonts/garamond/garamond-regular.woff") format("woff"), url("../fonts/garamond/garamond-regular.ttf") format("truetype"), url("../fonts/garamond/garamond-regular.svg#GaramondRegular") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/garamond/garamond-italic.woff2") format("woff2"), url("../fonts/garamond/garamond-italic.woff") format("woff"), url("../fonts/garamond/garamond-italic.ttf") format("truetype"), url("../fonts/garamond/garamond-italic.svg#GaramondItalic") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/roboto-light.woff2") format("woff2"), url("../fonts/roboto/roboto-light.woff") format("woff"), url("../fonts/roboto/roboto-light.ttf") format("truetype"), url("../fonts/roboto/roboto-light.svg#RobotoLight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/roboto-lightitalic.woff2") format("woff2"), url("../fonts/roboto/roboto-lightitalic.woff") format("woff"), url("../fonts/roboto/roboto-lightitalic.ttf") format("truetype"), url("../fonts/roboto/roboto-lightitalic.svg#RobotoLightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/roboto-regular.woff2") format("woff2"), url("../fonts/roboto/roboto-regular.woff") format("woff"), url("../fonts/roboto/roboto-regular.ttf") format("truetype"), url("../fonts/roboto/roboto-regular.svg#RobotoRegular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/roboto-italic.woff2") format("woff2"), url("../fonts/roboto/roboto-italic.woff") format("woff"), url("../fonts/roboto/roboto-italic.ttf") format("truetype"), url("../fonts/roboto/roboto-italic.svg#RobotoItalic") format("svg");
  font-weight: 400;
  font-style: italic; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem white inset; }

::-ms-clear {
  display: none; }

::-ms-reveal {
  display: none; }

label, input, textarea {
  width: 100%; }

label {
  margin-bottom: 1rem;
  font-weight: 400; }

input[type=text], textarea, #form_message {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  padding: .7rem 1rem;
  font-weight: 400;
  border-radius: 0;
  box-shadow: 0 0 0 0.2rem #d4a008 inset;
  background-color: transparent;
  transition: all .3s ease-in-out; }
  input[type=text]:hover, textarea:hover, #form_message:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  input[type=text]:focus, textarea:focus, #form_message:focus {
    box-shadow: 0 0 0 0.2rem #21201f inset;
    background-color: #fff; }

.form-group {
  margin: 1rem 0 0; }

.form-group-wrapper {
  display: flex;
  margin: -1rem; }
  @media (max-width: 576px) {
    .form-group-wrapper {
      flex-wrap: wrap;
      margin: 0; } }
  .form-group-wrapper .form-group {
    width: 50%;
    margin: 1rem 1rem 2rem; }
    @media (max-width: 576px) {
      .form-group-wrapper .form-group {
        width: 100%;
        margin: 1rem 0 0; } }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 1.4rem;
  color: rgba(33, 32, 31, 0.8); }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 1.4rem;
  color: rgba(33, 32, 31, 0.8); }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 1.4rem;
  color: rgba(33, 32, 31, 0.8); }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 1.4rem;
  color: rgba(33, 32, 31, 0.8); }

footer {
  position: relative;
  width: 100%;
  padding: 2rem !important;
  font: 500 1.3rem "Garamond", Georgia, serif;
  overflow: hidden;
  background-color: #fdfbf7; }
  footer p {
    margin: 0; }
  footer .footer__content, footer .footer__bottom {
    position: relative;
    z-index: 1; }
  footer .footer__content {
    padding: 2rem 0 4rem; }
    @media (max-width: 480px) {
      footer .footer__content {
        padding: 1rem 0 2rem; }
        footer .footer__content > .flex-container, footer .footer__content > .flex-container--row,
        footer .footer__content > .flex-row, footer .footer__content > .flex-container--column,
        footer .footer__content > .flex-column, footer .footer__content > .flex-container--row-reverse,
        footer .footer__content > .flex-row-reverse, footer .footer__content > .flex-container--column-reverse,
        footer .footer__content > .flex-column-reverse {
          flex-direction: column;
          align-items: center; } }
    footer .footer__content .logo-richard-apprederis {
      width: 12rem;
      height: 6rem;
      margin-bottom: 1rem;
      fill: #d4a008; }
    @media (max-width: 480px) {
      footer .footer__content .content-item {
        text-align: center; } }
    @media (min-width: 577px) {
      footer .footer__content .content-item.adresse, footer .footer__content .content-item.oeuvres {
        margin: 0 0 0 3rem;
        text-align: right; } }
    @media (max-width: 480px) {
      footer .footer__content .content-item.adresse, footer .footer__content .content-item.oeuvres {
        text-align: center; } }
    @media (min-width: 481px) {
      footer .footer__content .content-item.oeuvres {
        max-width: 16rem; } }
    @media (max-width: 576px) {
      footer .footer__content .content-item.oeuvres {
        margin-right: 3rem; } }
    @media (max-width: 480px) {
      footer .footer__content .content-item.oeuvres {
        margin: 0 0 2rem; } }
    footer .footer__content .content-item.oeuvres .title {
      margin-bottom: 1rem; }
    footer .footer__content .content-item.adresse .title {
      margin-bottom: 2rem; }
    @media (min-width: 577px) {
      footer .footer__content .content-item.resume {
        max-width: 20rem;
        margin-right: auto; } }
    @media (max-width: 576px) {
      footer .footer__content .content-item.resume {
        margin-bottom: 2rem; } }
    footer .footer__content .content-item .title {
      font: 700 1.5rem "Garamond", Georgia, serif; }
    footer .footer__content .content-item .contact-btn, footer .footer__content .content-item .footer-oeuvres-link {
      display: inline-block;
      margin: 1rem .25rem 0;
      padding: .1rem .2rem;
      text-align: center;
      text-decoration: none;
      color: #fff;
      transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
    footer .footer__content .content-item .contact-btn {
      font: 500 1.5rem "Garamond", Georgia, serif;
      background-color: #21201f; }
      footer .footer__content .content-item .contact-btn:hover {
        background-color: #d4a008; }
    footer .footer__content .content-item .footer-oeuvres-link {
      font: 500 1.4rem "Garamond", Georgia, serif;
      background-color: #d4a008; }
      footer .footer__content .content-item .footer-oeuvres-link:hover {
        background-color: #21201f; }
  footer .footer__bottom {
    padding-top: 2rem;
    border-top: 0.1rem solid #d4a008;
    text-align: center; }
  footer .footer__btn-top {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 2rem;
    width: 4rem;
    height: 4.4rem;
    cursor: pointer;
    outline: none;
    font: 400 2rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border: 0.2rem solid #d4a008;
    border-right: none;
    color: #d4a008;
    background-color: transparent;
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 4; }
    footer .footer__btn-top span {
      margin-top: -.5rem;
      margin-left: -.2rem; }
    footer .footer__btn-top:hover {
      color: #fff;
      background-color: #d4a008; }
  footer .bg-deco {
    position: absolute;
    top: 0;
    left: -5%;
    width: 100%;
    z-index: 0; }
    footer .bg-deco .logo-richard-apprederis {
      width: 80%;
      height: 80%;
      fill: #f7f3e9; }

.page-entete .page-entete__image, .page-entete .slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.b-lazy {
  max-width: 100%;
  opacity: 0;
  transition: opacity .2s ease; }

.b-lazy.b-loaded {
  opacity: 1; }

.accueil-categories .categories-wrapper {
  margin: -1rem; }

.accueil-categories .vign-categorie {
  position: relative;
  width: 25%;
  padding: 1rem; }
  @media (max-width: 992px) {
    .accueil-categories .vign-categorie {
      width: 33.33%; } }
  @media (max-width: 576px) {
    .accueil-categories .vign-categorie {
      width: 50%; } }
  .accueil-categories .vign-categorie:hover:before {
    background-color: rgba(212, 160, 8, 0.3); }
  .accueil-categories .vign-categorie:before {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    content: '';
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1; }
  .accueil-categories .vign-categorie a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3; }
  .accueil-categories .vign-categorie h2 {
    position: absolute;
    top: 50%;
    left: 1rem;
    right: 1rem;
    margin: 0;
    padding: 1rem;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    transform: translateY(-50%);
    color: #fff;
    z-index: 2; }
    @media (max-width: 992px) {
      .accueil-categories .vign-categorie h2 {
        font-size: 2rem; } }
    @media (max-width: 480px) {
      .accueil-categories .vign-categorie h2 {
        font-size: 1.4rem; } }
    .accueil-categories .vign-categorie h2 span {
      padding: .7rem .7rem .2rem;
      background-color: #21201f; }
      @media (max-width: 480px) {
        .accueil-categories .vign-categorie h2 span {
          padding: .5rem .7rem .2rem; } }
  .accueil-categories .vign-categorie img {
    width: 100%;
    height: auto; }

.oeuvre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 25%;
  padding: 2rem;
  transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
  @media (max-width: 992px) {
    .oeuvre {
      width: 33.33%; } }
  @media (max-width: 576px) {
    .oeuvre {
      width: 50%; } }
  @media (max-width: 480px) {
    .oeuvre {
      padding: 1rem; } }
  .oeuvre:hover {
    background-color: #fdfbf7; }
  .oeuvre .oeuvre-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .oeuvre .oeuvre-titre {
    margin: 0;
    text-align: center;
    font: 400 1.4rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase; }
  .oeuvre .oeuvre-pic {
    display: block;
    margin: 0 auto 1.5rem; }

.oeuvres-content {
  padding-bottom: 7rem; }
  @media (max-width: 768px) {
    .oeuvres-content {
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .oeuvres-content {
      padding-bottom: 2rem; } }
  .oeuvres-content .categorie-soustitre {
    margin: .5rem 0 0;
    font: 500 1.6rem "Garamond", Georgia, serif;
    font-style: italic; }

.oeuvre-content {
  padding-bottom: 7rem; }
  @media (max-width: 768px) {
    .oeuvre-content {
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .oeuvre-content {
      padding-bottom: 2rem; } }
  @media (max-width: 768px) {
    .oeuvre-content > .flex-container, .oeuvre-content > .flex-container--row,
    .oeuvre-content > .flex-row, .oeuvre-content > .flex-container--column,
    .oeuvre-content > .flex-column, .oeuvre-content > .flex-container--row-reverse,
    .oeuvre-content > .flex-row-reverse, .oeuvre-content > .flex-container--column-reverse,
    .oeuvre-content > .flex-column-reverse {
      flex-direction: column;
      align-items: center; } }
  .oeuvre-content .slider-wrapper, .oeuvre-content .content-wrapper {
    width: 50%; }
    @media (max-width: 768px) {
      .oeuvre-content .slider-wrapper, .oeuvre-content .content-wrapper {
        width: 100%; } }
  .oeuvre-content .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .oeuvre-content .galerie-oeuvre {
    width: 55rem;
    outline: none; }
    .oeuvre-content .galerie-oeuvre a {
      outline: none; }
    .oeuvre-content .galerie-oeuvre div {
      text-align: center; }
  .oeuvre-content .oeuvre-pic-big {
    max-height: 55rem;
    max-width: 55rem; }
  .oeuvre-content .oeuvre-single-pic {
    width: initial;
    height: auto;
    margin: 0 auto; }
  .oeuvre-content .slick-dots button:before {
    border: 0.2rem solid #d4a008; }
  .oeuvre-content .slick-dots .slick-active button:before {
    background-color: #d4a008; }
  .oeuvre-content .content-wrapper {
    padding: 0 2rem; }
    @media (max-width: 768px) {
      .oeuvre-content .content-wrapper {
        padding: 3rem 0 0;
        text-align: center; } }
    @media (max-width: 480px) {
      .oeuvre-content .content-wrapper {
        padding-top: 2rem; } }
  .oeuvre-content .vente {
    margin-top: 3rem; }
    @media (max-width: 480px) {
      .oeuvre-content .vente {
        margin-top: 2rem; } }
    .oeuvre-content .vente strong {
      font-size: 1.8rem;
      color: #49a01f; }
      @media (max-width: 480px) {
        .oeuvre-content .vente strong {
          font-size: 1.6rem; } }
  .oeuvre-content .vente-btn-wrapper {
    align-items: center; }
    @media (max-width: 768px) {
      .oeuvre-content .vente-btn-wrapper {
        justify-content: center; } }
  .oeuvre-content .vente-btn {
    margin-bottom: 1rem;
    padding: .6rem .7rem .4rem;
    cursor: pointer;
    font: 500 1.7rem "Garamond", Georgia, serif;
    text-decoration: none;
    text-align: center;
    color: #fff;
    background-color: #21201f;
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
    .oeuvre-content .vente-btn:first-of-type {
      margin-right: 1rem; }
    .oeuvre-content .vente-btn:hover {
      background-color: #d4a008; }
  .oeuvre-content .basic-btn {
    display: inline-block; }
    .oeuvre-content .basic-btn svg {
      margin-right: 1rem;
      fill: #fff; }
    .oeuvre-content .basic-btn span, .oeuvre-content .basic-btn svg {
      vertical-align: middle; }

.news-single {
  position: relative; }
  .news-single:hover .fake-btn {
    background-color: #d4a008; }
  .news-single .news-single-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
  .news-single .news-single-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%; }
    .news-single .news-single-content > div {
      max-width: 100%; }
  .news-single .entete-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 2rem; }
    @media (max-width: 480px) {
      .news-single .entete-wrapper {
        flex-direction: column; } }
    .news-single .entete-wrapper:after {
      position: absolute;
      left: 0;
      bottom: -.1rem;
      width: 5rem;
      height: .2rem;
      content: '';
      background-color: #d4a008; }
  .news-single .entete-image {
    min-width: 11.5rem;
    margin-right: 2rem; }
    @media (max-width: 480px) {
      .news-single .entete-image {
        order: 1;
        margin: 1.5rem 0 0; } }
    .news-single .entete-image img {
      width: 100%;
      height: auto; }
  .news-single h2, .news-single h3 {
    margin-bottom: 1rem;
    font: 400 2.1rem "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    @media (max-width: 480px) {
      .news-single h2, .news-single h3 {
        font-size: 1.8rem; } }
  .news-single .news-single-date {
    margin: 0;
    font: 500 1.5rem "Garamond", Georgia, serif;
    font-style: italic; }
  .news-single .txt-wrapper {
    margin-bottom: 2rem; }
  .news-single .fake-btn {
    margin-top: auto;
    padding: 0 .3rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #21201f;
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }

.actualites-content {
  padding-bottom: 7rem; }
  @media (max-width: 768px) {
    .actualites-content {
      padding-bottom: 4rem; } }
  @media (max-width: 480px) {
    .actualites-content {
      padding-bottom: 2rem; } }
  .actualites-content .news-single {
    width: 50%;
    padding: 3rem 2rem;
    transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1); }
    @media (max-width: 840px) {
      .actualites-content .news-single {
        width: 100%;
        padding: 2rem; } }
    @media (max-width: 576px) {
      .actualites-content .news-single {
        padding: 1.5rem 1rem; } }
    .actualites-content .news-single:hover {
      background-color: #fdfbf7; }

.actualite-content img {
  width: 100%;
  height: auto; }

.actualite-content hr {
  margin: 0;
  color: rgba(212, 160, 8, 0.5);
  background-color: rgba(212, 160, 8, 0.5); }

.actualite-content .news-duree {
  margin-bottom: 2rem;
  font: 500 2.2rem "Garamond", Georgia, serif;
  text-align: center; }

.actualite-content a:not(.basic-btn) {
  color: #d4a008; }

.artiste-id table {
  margin: 0;
  table-layout: auto; }
  .artiste-id table th {
    text-align: right; }
  .artiste-id table th, .artiste-id table td {
    padding: .5rem;
    vertical-align: baseline; }

.artiste-bio img {
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
  margin: 0 auto 3rem; }

.contact-form .g-recaptcha {
  margin-top: 3rem; }
  @media (max-width: 576px) {
    .contact-form .g-recaptcha {
      margin-top: 2rem; } }
  .contact-form .g-recaptcha > div {
    margin: 0 auto; }

@media (max-width: 480px) {
  .contact-form #rc-imageselect, .contact-form .g-recaptcha {
    transform: scale(0.8) !important;
    transform-origin: 0 0 !important; } }
