
	// Navigation

		header {
      position: fixed;
      width: 100%;
      background-color: $ivoire;
      z-index: 4;

      & > .container {
        flex-direction: column;
        border-bottom: 2rem solid $white;

        @media (max-width: $large) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

			&.is-mobile-nav-open .header__links {
				pointer-events: auto;
        opacity: 1;
			}

      .header__logo {
        display: block;
        margin-top: 1rem;
        padding: 1rem;
        text-align: center;

        @media (max-width: $small-plus) {
          padding: .5rem;
        }
      }

      .logo-richard-apprederis {
        width: 14rem;
        height: 7rem;
        fill: $anthracite;
        transition: all 1s $ease-slow;

        @media (max-width: $small-plus) {
          width: 10rem;
          height: 5rem;
        }

        &:hover {
          fill: $ocre;
        }
      }

      &.is-scroll .logo-richard-apprederis {
        width: 10rem;
        height: 5rem;

        @media (max-width: $small-plus) {
          width: 8rem;
          height: 4rem;
        }
      }

      .header__recherche {
        @media (max-width: $small-plus) {
          display: none;
        }

        .header__recherche-input {
          width: 18rem;
          background-color: transparent;
          transition: all .3s ease-in-out;

          &:hover {
            background-color: rgba($white, .5);
          }

          &:focus {
            box-shadow: 0 0 0 .2rem $anthracite inset;
            background-color: $white;
          }
        }
      }

      .links-wrapper {
        align-items: center;
        justify-content: space-between;
        margin: 0 -1rem 1.5rem 0;

        @media (max-width: $large) {
          margin: 0;
        }
      }

      .header__recherche-group {
        align-items: center;

        .loupe {
          margin-left: 1rem;
          fill: $ocre;
        }
      }

      .header__links {
        @media (max-width: $large) {
          flex-wrap: nowrap;
          position: absolute;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          top: 100%;
          left: 0;
          right: 0;
          width: 100%;
          height: calc(100vh - 10.4rem);
          padding: 1.5rem;
          pointer-events: none;
          text-align: center;
          opacity: 0;
          background-color: rgba($white, .95);
          transition: opacity 1s $ease-slow;
        }

        @media (max-width: $small-plus) {
          height: calc(100vh - 7.4rem);
        }

        @media (max-width: $tiny) {
          justify-content: flex-start;
        }
      }

      .categories-drop-down {
        position: relative;

        .nav-link:after {
          content: none;
        }

        @media (min-width: $large + 1) {
          &:hover .nav-categories-wrapper {
            pointer-events: auto;

            &:before {
              width: 100%;
            }

            .nav-sub-link {
              opacity: 1;
            }
          }
        }
      }

      .nav-categories-wrapper {
        position: relative;

        @media (min-width: $large + 1) {
          position: absolute;
          top: 3.3rem;
          left: 50%;
          pointer-events: none;
          transform: translateX(-50%);
        }

        &:before {
          position: absolute;
          left: 50%;
          top: -.2rem;
          height: .2rem;
          width: 0;
          content: '';
          background-color: $ocre;
          transform: translateX(-50%);
          transition: all .7s $ease-slow;
        }
      }

      .nav-sub-link {
        display: block;
        padding: 1.2rem 2rem 1rem;
        font: 400 1.4rem $font-family-base;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 1s $ease-slow, background-color 1s $ease-slow, opacity 1s $ease-slow .3s;

        @media (min-width: $large + 1) {
          padding: 1rem;
          background-color: $white;
          opacity: 0;

          &:hover {
            color: $white;
            background-color: $anthracite;
          }
        }

        @media (max-width: $large) {
          font-size: 1.3rem;
        }

        @media (max-width: $tiny) {
          padding: .5rem 1rem;
        }
      }

      .nav-link {
        display: inline-block;
        position: relative;
        margin: 0 1rem;
        padding: .8rem 1rem;
        font: 700 2rem $font-family-headings;
        text-decoration: none;
        transition: color .3s ease-in-out;

        @media (min-width: $large + 1) {
          &:after {
            position: absolute;
            left: 50%;
            bottom: .3rem;
            height: .2rem;
            width: 0;
            content: '';
            background-color: $ocre;
            transform: translateX(-50%);
            transition: all .7s $ease-slow;
          }

          &:hover, &.is-active {
            &:after {
              width: calc(100% - 5rem);
            }
          }
        }

        @media (max-width: $large) {
          font-size: 2.2rem;
        }

        @media (max-width: $tiny) {
          padding: .5rem 1rem;
          font-size: 2rem;
        }

        &.is-active {
          color: $ocre;
        }

        &.nav-link-contact-small {
          @media (min-width: $large + 1) {
            display: none;
          }
        }

        &.nav-link-contact {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: $white;
          background-color: $ocre;
          transition: background-color 1s $ease-slow;

          @media (max-width: $large) {
            display: none;
          }

          &:after {
            content: none;
          }

          &:hover {
            background-color: $anthracite;
          }
        }

        &.is-active-contact {
          background-color: $anthracite;
        }
      }

			.hamburger {
				justify-content: center;
				align-items: center;
        margin-left: 2rem;
        outline: none;

				@media (min-width: $large + 1) {
					display: none;
				}

				.hamburger-label {
					margin: .3rem 1.2rem 0 0;
			    text-transform: uppercase;
			    font: 700 2rem $font-family-headings;
			    color: $ocre;
				}
			}
		}

    // Style vignettes recherche

    .autocomplete-suggestion {
      display: flex;

      &:hover .autocomplete-suggestion-contenu {
        color: $white;
        background-color: $anthracite;
      }

      .autocomplete-suggestion-contenu {
        padding: 0 .5rem;
        cursor: pointer;
        background-color: $white;
        transition: all 1s $ease-slow;

        span {
          width: 100%;
        }

        .recherche-produit {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 1.5rem;
        }

        .recherche-categorie {
          font-size: 1.3rem;
          font-style: italic;
        }
      }
    }
