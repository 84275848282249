
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
      font: 300 1.5rem $font-family-base;
      color: $anthracite;
      background-color: $ivoire;

      &.is-fixed {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
		}

		main {
			flex: 1 1 auto;
      width: 100%;
      background-color: $white;

      &.container {
        margin-top: 15.5rem;
        padding: 2rem;

        @media (max-width: $large) {
          margin-top: 10.4rem;
        }

        @media (max-width: $small-plus) {
          margin-top: 7.4rem;
        }
      }

      &.is-scroll {
        margin-top: 13.5rem;

        @media (max-width: $large) {
          margin-top: 8.4rem;
        }

        @media (max-width: $small-plus) {
          margin-top: 5.4rem;
        }
      }
		}

    h1 {
      font: 700 5rem $font-family-headings;

      @media (max-width: $medium) {
        font-size: 3.5rem;
      }

      @media (max-width: $tiny) {
        font-size: 2.5rem;
      }

      span {
        font-weight: 500;
        font-size: 3.5rem;

        @media (max-width: $medium) {
          font-size: 2.8rem;
        }

        @media (max-width: $tiny) {
          font-size: 2rem;
        }
      }
    }

    h2 {
      margin-bottom: 2rem;
      font: 500 3.5rem $font-family-headings;

      @media (max-width: $medium) {
        font-size: 2.9rem;
      }

      @media (max-width: $small) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: $tiny) {
        font-size: 2.5rem;
      }
    }

    h3 {
      margin-bottom: 1.5rem;
      font: 400 2.1rem $font-family-base;

      @media (max-width: $medium) {
        font-size: 1.9rem;
      }

      @media (max-width: $tiny) {
        font-size: 1.7rem;
      }
    }

    ///// Corrections Slick slider
    .slides-wrapper {
      .slick-list, .slick-track, .slick-slide, .slick-slide div, .slide, .slide-link {
        height: 100%;
      }
    }


    ////// Dimensions custo container

    %container {
      position: relative;
      margin: 0 auto;
      padding: 0 2rem;

      @media (max-width: $small) {
        padding: 0 1.5rem;
      }
    }

    .container {
      max-width: 124rem;
      @extend %container;
    }

    .container-small {
      max-width: 94rem;
      @extend %container;

      @media (max-width: $medium) {
        padding: 0;
      }
    }

    .container-tiny {
      max-width: 75rem;
      @extend %container;

      @media (max-width: $medium) {
        padding: 0;
      }
    }

    .p-section {
      padding-top: 4rem;
      padding-bottom: 7rem;

      @media (max-width: $medium) {
        padding-top: 3rem;
        padding-bottom: 4rem;
      }

      @media (max-width: $tiny) {
        padding-bottom: 2rem;
      }
    }

    .page-entete {
      .slides-wrapper, .page-entete__image, .map {
        position: relative;
        width: 100%;
        max-height: 70rem;
        height: calc(100vh - 19.5rem);

        @media (max-width: $large) {
          max-height: 50rem;
        }

        @media (max-width: $medium) {
          max-height: 40rem;
        }

        @media (max-width: $small) {
          max-height: 30rem;
        }

        @media (max-width: $tiny) {
          max-height: 20rem;
        }
      }

      .page-entete__image, .slide {
        @extend %bg-cover;
      }

      .slide {
        height: 100%;
      }

      h1 {
        margin: 0;
        text-align: center;
      }

      .bloc-texte p:first-of-type {
        margin-top: 3rem;

        @media (max-width: $small) {
          margin-top: 2rem;
        }
      }

      .bloc-texte p:last-of-type {
        margin-bottom: 0;
      }

      .bloc-texte p:not(.news-date):not(.oeuvres-categories):not(.oeuvre-intro):not(.fil-ariane) {
        font-size: 1.8rem;

        @media (max-width: $small) {
          font-size: 1.6rem;
        }

        &:first-of-type:first-letter {
          font: 500 3rem $font-family-headings;
          font-style: italic;

          @media (max-width: $small) {
            font-size: 2rem;
          }
        }
      }

      .news-date {
        margin: 0;
        font: 500 1.5rem $font-family-headings;
        font-style: italic;
        text-align: center;

        @media (max-width: $medium) {
          font-size: 1.3rem;
        }
      }

      .oeuvres-categories {
        margin: 2rem 0 0;
        font-size: 1.5rem;

        @media (max-width: $medium) {
          font-size: 1.3rem;
        }

        a {
          display: inline-block;
          padding: 0 .5rem .1rem;
          margin: .5rem .25rem 0;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          color: $white;
          background-color: $anthracite;
          transition: background-color 1s $ease-slow;

          &:hover {
            background-color: $ocre;
          }
        }
      }

      .oeuvre-intro {
        margin: 0;
        font: 500 2rem $font-family-headings;
        font-style: italic;
        text-align: center;
      }

      .fil-ariane {
        font-size: 1.3rem;
        text-align: center;
        color: rgba($anthracite, .7);

        @media (max-width: $medium) {
          margin-top: 1rem !important;
          margin-bottom: 0;
        }

        a {
          color: inherit;
        }
      }

      .tags-wrapper {
        margin: 3rem -.5rem 0;
        text-align: center;

        a {
          display: inline-block;
          margin: .5rem .5rem 0;
          padding: .2rem .7rem .4rem;
          font-size: 1.6rem;
          text-align: center;
          text-decoration: none;
          color: $white;
          background-color: $ocre;
          transition: background-color 1s $ease-slow;

          @media (max-width: $tiny) {
            font-size: 1.4rem;
          }

          &.tags-close {
            width: 3rem;
            background-color: $error;
          }

          &.button-active {
            background-color: $anthracite;
          }

          &:hover {
            background-color: $anthracite;
          }
        }
      }
    }

    ///// Style custo bouton

    a.basic-btn, button.basic-btn {
      display: table;
      margin: 3rem auto 0;
      padding: 1.5rem 2rem;
      font: 700 2rem $font-family-headings;
      text-align: center;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border: none;
      box-shadow: none;
      outline: none;
      color: $white;
      background-color: $ocre;
      transition: background-color 1s $ease-slow;

      @media (max-width: $small) {
        font-size: 1.8rem;
      }

      &:hover {
        background-color: $anthracite;
      }
    }

    ///// Pagination

    .pagination {
      width: 100%;
      margin: 3rem 0 1rem;
      font-weight: 400;
      text-align: center;

      @media (max-width: $small) {
        margin-top: 2rem;
      }

      span, a {
        display: inline-block;
        width: 4rem;
        height: 4rem;

        @media (max-width: $tiny) {
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.3rem;
        }
      }

      .current {
        padding: 1rem;
        color: $white;
        background-color: $anthracite;

        @media (max-width: $tiny) {
          padding: .5rem;
        }
      }

      a {
        padding: 1rem;
        text-decoration: none;
        background-color: rgba($ocre, .3);
        transition: all 1s $ease-slow;

        @media (max-width: $tiny) {
          padding: .5rem;
        }

        &:hover {
          color: $white;
          background-color: $ocre;
        }
      }
    }

    ///// Mentions légales, 404 et plan du site

    .mentions-legales, .plan-du-site, .erreur-404, .erreur-500 {
      padding-bottom: 8rem;

      @media (max-width: $medium) {
        padding-bottom: 4rem;
      }

      @media (max-width: $tiny) {
        padding-bottom: 2rem;
      }

      h2:not(:first-of-type) {
        margin-top: 3rem;
      }
    }

    .plan-du-site .page--sitemap__first {
      padding: 0;

      li {
        list-style-type: none;

        a {
          display: inline-block;
          padding: .5rem;
          text-decoration: none;
          transition: color 1s $ease-slow;

          &:hover {
            color: $ocre;
          }
        }
      }

      & > li {
        text-transform: uppercase;
        font-weight: 400;
      }

      & > ul li {
        font-weight: 400;

        & > ul li {
          font-weight: 300;
        }
      }
    }

    ///// Autres

    .recherche-content {
      padding-bottom: 8rem;
    }


